import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Auth } from 'aws-amplify'

export default function EmailConfirmation({ handleOpenModal, setHandleOpenModal, setEmailAddress, authStatus }) {
  const [email, setEmail] = useState('')

  //Handle verification errors
  const [invalidEmail, setInvalidEmail] = useState(false)

  const handleClose = () => {
    setHandleOpenModal(false)
  }

  const handleSubmitForgetPassword = async (emailAddress) => {
    setInvalidEmail(false)
    try {
      await Auth.forgotPassword(emailAddress)
      setEmailAddress(emailAddress)
      setEmail('')
      handleClose()
    } catch (error) {
      console.log(error)
      setInvalidEmail(true)
    }
  }

  return (
    <div>
      <Dialog maxWidth={'xs'} open={handleOpenModal} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>電子メールアドレス</DialogTitle>
        <DialogContent>
          <DialogContentText>確認コードを送信するアカウントのメールアドレスを入力してください</DialogContentText>
          <TextField
            error={invalidEmail}
            autoFocus
            id='outlined-basic'
            label='
            電子メールアドレス'
            variant='outlined'
            type='email'
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose()
              authStatus('signIn')
            }}
            color='primary'>
            キャンセル
          </Button>
          <Button onClick={() => handleSubmitForgetPassword(email)} color='primary'>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
