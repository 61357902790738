import { useFetchedData } from '../components/ui/hooks/FetchedDataContext'
import { processArray } from './getProcessedDates'

export function GetLatestFileByDate() {
  const { fetchedData } = useFetchedData()

  let processedFetchedDates = []
  if (fetchedData.fetchedDates.length) {
    processArray(fetchedData.fetchedDates).map((value) => {
      return processedFetchedDates.push(value)
    })
  }

  if (processedFetchedDates.length) {
    return processedFetchedDates.pop()
  }
}
