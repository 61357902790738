function convertDate(dateString) {
  let year = parseInt(dateString.substr(0, 2), 10)
  let month = parseInt(dateString.substr(2, 2), 10) - 1
  let day = parseInt(dateString.substr(4, 2), 10)

  if (year < 50) {
    year += 2000
  } else {
    year += 1900
  }
  return new Date(year, month, day)
}

export function processArray(array) {
  return array.map((str) => {
    const modifiedStr = str.substring(0, str.indexOf('_'))
    return convertDate(modifiedStr)
  })
}
