import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import LayoutPage from './components/pages/LayoutPage'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { BrowserRouter as Router } from 'react-router-dom'
import Authenticate from './features/authentication/services/Authenticate'
import { SessionProvider } from './features/authentication/hooks/SessionContext'
import { FetchedDataProvider } from './components/ui/hooks/FetchedDataContext'
import { checkLocation } from './features/authentication/components/restriction'

function App() {
  const [loading, setLoading] = useState(true)

  // Overrides ListItem Font Family
  const muiTheme = createMuiTheme({
    typography: {
      fontFamily: 'Roboto Mono',
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    },
  })

  //For restriction of access if outside japan
  useEffect(() => {
    checkLocation().then((data) => setLoading(data))
  }, [])

  return (
    <>
      {!loading ? (
        <>
          <SessionProvider>
            <FetchedDataProvider>
              <Authenticate>
                <ThemeProvider theme={muiTheme}>
                  <Router>
                    <LayoutPage />
                  </Router>
                </ThemeProvider>
              </Authenticate>
            </FetchedDataProvider>
          </SessionProvider>
        </>
      ) : null}
    </>
  )
}

const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)
