import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

export default function LineChartData({ historicalData, startDate }) {
  function convertDateToMMDD(dateString) {
    const date = new Date(dateString)
    const month = date.getMonth() + 1
    const day = date.getDate()

    return `${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
  }
  function getDateCorrespondsToWeek(date, index) {
    let newDate = new Date(date)

    newDate.setDate(newDate.getDate() - 7 * index)
    let mmdd = convertDateToMMDD(newDate)
    return mmdd
  }

  // eslint-disable-next-line
  let data = historicalData.map((value, index) => {
    return {
      name: getDateCorrespondsToWeek(startDate, index),
      data: value,
      percentage: false,
    }
  })

  return (
    <ResponsiveContainer width='100%' height={150}>
      <LineChart data={data} padding={{ top: 0, right: 0, left: 10, bottom: 0 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' angle={-90} textAnchor='end' interval='preserveStartEnd' reversed height={50} />
        <YAxis domain={[(dataMin) => Math.floor(dataMin * 0.9), (dataMax) => Math.ceil(dataMax * 1.01)]} width={65} />
        <Tooltip />
        <Line type='monotone' dataKey='data' stroke='#82ca9d' />
      </LineChart>
    </ResponsiveContainer>
  )
}
