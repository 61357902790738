import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BackgroundImage from '../../assets/img/gradientColorBg.jpg'
import { Container, Grid } from '@material-ui/core'
import { getListofData } from '../../data/fetchDataListFile'
import { useFetchedData } from '../ui/hooks/FetchedDataContext'
import { useNavigate } from 'react-router-dom'
import { Storage } from 'aws-amplify'

const useStyles = makeStyles(() => ({
  backgroundDashboard: {
    backgroundImage: `url(${BackgroundImage})`,
    height: '100%',
    width: '100%',
  },
  topMargin: {
    marginTop: 50,
  },
  backgroundApp: {
    display: 'flex',
    justifyContent: 'center',
    transition: '0.2s, transform 300ms',
    marginBottom: 40,
  },
  selectable: {
    cursor: 'pointer',
    '&:hover': { transform: 'scale(1.1)' },
  },
  nonSelectable: {
    filter: 'grayscale(1)',
    color: 'rgba(255, 255, 255, 0.4) ',
  },
}))

export default function AppDashboard() {
  const navigate = useNavigate()
  const { fetchedData, setFetchedData } = useFetchedData()
  const classes = useStyles()

  //add eslint disable due to setAppnames is not being called and it needs to be useState for the code to run correctly
  // eslint-disable-next-line
  const [appNames, setAppNames] = useState([
    { title: 'Magapoke', isLive: true, appName: 'magapoke' },
    { title: 'Palcy', isLive: true, appName: 'palcy' },
    { title: 'Comicdays', isLive: true, appName: 'comicdays' },
  ])

  //Get the list of data from the start
  useEffect(() => {
    appNames.forEach((value) => {
      logoImage(value.appName).then((data) => (value.imageUrl = data))
    })

    getListofData(fetchedData.fetchedAppName ? fetchedData.fetchedAppName : 'magapoke').then((data) => {
      setFetchedData({ ...fetchedData, fetchedDates: data })
    })
    //add eslint disable since useEffect will only work once
    // eslint-disable-next-line
  }, [])

  const handleClickApp = async (isLive, appName) => {
    if (isLive) {
      await getListofData(appName).then((data) => {
        setFetchedData({ ...fetchedData, fetchedDates: data, fetchedAppName: appName })
      })
      navigate('/weekly')
    }
  }

  const logoImage = async (appName) => {
    const dataImage = await Storage.get(`app_logo_images/${appName}.png`)
    const res = await dataImage

    return res
  }

  return (
    <div className={classes.backgroundDashboard}>
      <Container>
        <Grid container className={classes.topMargin}>
          {appNames[0].imageUrl
            ? appNames.map(({ isLive, appName, imageUrl }, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    md={4}
                    className={`${classes.backgroundApp} ${isLive ? classes.selectable : classes.nonSelectable}`}
                    onClick={() => handleClickApp(isLive, appName)}>
                    <img src={imageUrl} alt='app logo' width={250} />
                  </Grid>
                )
              })
            : null}
        </Grid>
      </Container>
    </div>
  )
}
