import React from 'react'
import { Popover as MuiPopover, Box, Paper, Typography, Grid, makeStyles } from '@material-ui/core'
import ChartData from './Chart'
import LineChartData from './LineChart'

const useStyles = makeStyles({
  popoverPaper: {
    marginTop: '10px',
    overflowX: 'unset',
    overflowY: 'unset',
    width: '310px',
    '&::before': {
      boxShadow:
        '20px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 1px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      opacity: 0.9,
      content: '""',
      position: 'absolute',
      top: 0,
      left: 155,
      marginTop: '-10px',
      width: 10,
      height: 10,
      backgroundColor: 'white',
      transform: 'translate(-50%, 50%) rotate(-45deg)',
      clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
    },
  },
})

const Popover = ({ anchor, onClose, nodeData }) => {
  const classes = useStyles()
  if (!nodeData) return <div></div>
  const { data } = nodeData

  console.log(data)
  // const notReachedValue = data.data.potentialTarget - data.data.target
  // const formatDecimal = (value) => +parseFloat(value).toFixed(2)

  return (
    <MuiPopover
      open={anchor !== null}
      anchorEl={anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        paper: classes.popoverPaper,
      }}>
      <Paper
        style={{
          padding: 10,
          minWidth: 230,
          opacity: 0.8,
        }}>
        <Box>
          <Typography variant='h6' title={data.contentName}>
            {data.name}
          </Typography>
        </Box>
        {data.explanation && (
          <Box mt={1} mb={2}>
            <Typography variant='body2'>{data.explanation}</Typography>
          </Box>
        )}

        {typeof data.date === 'object' && (
          <Box mb={1}>
            <Typography variant='body1'>
              From {data.date.from} to {data.date.to}
            </Typography>
          </Box>
        )}

        <Box mb={1}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant='body1' style={{ fontWeight: 'bold', fontSize: '11px' }}>
                先週実績値
              </Typography>
              <Typography variant='body1'>{data.data.potentialTarget}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body1' style={{ fontWeight: 'bold', fontSize: '11px' }}>
                実績値
              </Typography>
              <Typography variant='body1'>{data.data.target}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='body1' style={{ fontWeight: 'bold', fontSize: '11px' }}>
                {data.data.percentOK.label}
              </Typography>
              <Typography variant='body1'>
                {data.data.percentOK.value > 0 ? `+${data.data.percentOK.value}` : data.data.percentOK.value} %
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {data.historical ? (
          <Box mr={3} mt={3}>
            <ChartData historicalData={data.historical} startDate={data.date.from} />
          </Box>
        ) : null}

        {data.historical_1yr ? (
          <Box mr={3} mt={3}>
            <LineChartData historicalData={data.historical_1yr} startDate={data.date.from} />
          </Box>
        ) : null}
      </Paper>
    </MuiPopover>
  )
}

export default Popover
