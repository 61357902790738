import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Copyright } from './Copyright'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))
export default function Login({ authStatus }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  //Error Handling state
  const [invalidCredentials, setInvalidCredentials] = useState({ boolean: false, message: '' })
  const classes = useStyles()

  const handleSubmit = async (event) => {
    event.preventDefault()
    setInvalidCredentials({ boolean: false, message: '' })
    try {
      await Auth.signIn(email, password)
      setInvalidCredentials(false)
    } catch (error) {
      setInvalidCredentials({ boolean: true, message: 'メールアドレス/パスワードが無効です' })
    }
  }
  return (
    <>
      <Typography component='h1' variant='h5'>
        ログインする
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          error={invalidCredentials.boolean}
          helperText={invalidCredentials.message}
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label='
  電子メールアドレス'
          name='email'
          autoComplete='email'
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          error={invalidCredentials.boolean}
          helperText={invalidCredentials.message}
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='password'
          label='
  パスワード'
          type='password'
          id='password'
          autoComplete='current-password'
          onChange={(e) => setPassword(e.target.value)}
        />
        {/* remove remember me  */}
        {/* <FormControlLabel control={<Checkbox value='remember' color='primary' />} label='情報を記憶する' /> */}
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={(e) => handleSubmit(e)}>
          ログインする
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href='#' variant='body2' onClick={() => authStatus('forgotPassword')}>
              パスワードを忘れた？
            </Link>
          </Grid>
          <Grid item>
            <Link style={{ cursor: 'pointer' }} variant='body2' onClick={() => authStatus('signUp')}>
              アカウントをお持ちでない場合サインアップ
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </>
  )
}
