import React, { useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import AuthenticationBg from '../../../assets/img/authenticationBg.png'
import SignUp from './SignUp'
import Login from './Login'
import ForgetPassword from './ForgetPassword'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${AuthenticationBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SideDesignAuth() {
  const [authStatus, setAuthStatus] = useState('signIn')
  const classes = useStyles()

  return (
    <Grid container component='main' className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {authStatus === 'signIn' ? (
            <Login authStatus={(authStatus) => setAuthStatus(authStatus)} />
          ) : authStatus === 'signUp' ? (
            <SignUp authStatus={(authStatus) => setAuthStatus(authStatus)} />
          ) : authStatus === 'forgotPassword' ? (
            <ForgetPassword authStatus={(authStatus) => setAuthStatus(authStatus)} />
          ) : null}
        </div>
      </Grid>
    </Grid>
  )
}
