import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { Auth } from 'aws-amplify'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Copyright } from './Copyright'
import EmailConfirmation from './EmailConfirmation'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function ForgetPassword({ authStatus }) {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const [handleOpenModal, setHandleOpenModal] = useState(true)

  //Handling error for the credentials
  const [invalidCode, setInvalidCode] = useState({ boolean: false, message: '' })
  const [invalidPassword, setInvalidPassword] = useState({ boolean: false, message: '' })

  const handleSubmitNewPassword = async (event, emailAddress, verificationCode, newPassword) => {
    event.preventDefault()
    setInvalidCode({ boolean: false, message: '' })
    setInvalidPassword({ boolean: false, message: '' })
    try {
      if (password === passwordConfirmation && passwordConfirmation !== '') {
        await Auth.forgotPasswordSubmit(emailAddress, verificationCode, newPassword)
        authStatus('signIn')
        setEmail('')
      }
      if (password !== passwordConfirmation || passwordConfirmation === '') {
        setInvalidPassword({
          boolean: true,
          message: '大文字、小文字、数字、特殊文字を含めて 8 文字以上である必要があります',
        })
      }
    } catch (error) {
      if (error.name === 'LimitExceededException') {
        alert('限度超過。後でもう一度やり直してください')
      }
      if (error.name === 'CodeMismatchException') {
        setInvalidCode({ boolean: true, message: '無効な検証コード' })
      }
      if (error.name === 'InvalidPasswordException') {
        setInvalidPassword({
          boolean: true,
          message: '大文字、小文字、数字、特殊文字を含めて 8 文字以上である必要があります',
        })
      }
    }
  }

  return (
    <>
      <Typography component='h1' variant='h5'>
        パスワードを再設定する
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={invalidCode.boolean}
              helperText={invalidCode.message}
              variant='outlined'
              required
              fullWidth
              id='code'
              label='確認コード'
              name='code'
              type='number'
              onChange={(e) => setCode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={invalidPassword.boolean}
              helperText={invalidPassword.message}
              variant='outlined'
              required
              fullWidth
              name='password'
              label='新しいパスワード'
              type='password'
              id='password'
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={invalidPassword.boolean}
              helperText={invalidPassword.message}
              variant='outlined'
              required
              fullWidth
              name='password-confirmtation'
              label='新しいパスワード（確認用）'
              type='password'
              id='password-confirmtation'
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={(e) => {
            handleSubmitNewPassword(e, email, code, passwordConfirmation)
          }}>
          パスワードを再設定する
        </Button>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Link style={{ cursor: 'pointer' }} variant='body2' onClick={() => authStatus('signIn')}>
              戻る
            </Link>
          </Grid>
        </Grid>
      </form>
      <EmailConfirmation
        handleOpenModal={handleOpenModal}
        setHandleOpenModal={(handleOpenModal) => setHandleOpenModal(handleOpenModal)}
        setEmailAddress={(emailAddress) => setEmail(emailAddress)}
        authStatus={(status) => authStatus(status)}
      />
      <Box mt={5}>
        <Copyright />
      </Box>
    </>
  )
}
