import { Storage } from 'aws-amplify'

export async function getListofData(appName) {
  let array = []

  const isValidFilename = (filename) => {
    const filteredFilename = filename.replace(`applogs/${appName}/`, '')
    const pattern = /^[0-9]{6}_(.*?)_data\.json$/
    return pattern.test(filteredFilename)
  }

  try {
    const folderName = 'applogs'
    const data = await Storage.list(`${folderName}/${appName}/`, { level: 'public' })

    data.results.forEach((value) => {
      if (isValidFilename(value.key)) {
        array.push(value.key.replace(`applogs/${appName}/`, ''))
      }
    })
  } catch (error) {}

  return array
}
