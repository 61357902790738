/* eslint-disable react-hooks/exhaustive-deps */
import React, { StrictMode, useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ReactFlowProvider } from 'reactflow'
import 'reactflow/dist/base.css'
import CampaignProfile from './CampaignProfile'
import './styles.css'
import { useFetchedData } from '../../components/ui/hooks/FetchedDataContext'
import { fetchDataFile } from '../../data/fetchDataFile'
import { getFormattedDate } from '../../utils/getFormattedDate'
import { GetLatestFileByDate } from '../../utils/getLatestFileDate'
import BackgroundGradient from '../../assets/img/gradientColorBg.jpg'

const App = () => {
  const mode = 'profile'
  const { fetchedData } = useFetchedData()
  const [currentSteps, setCurrentSteps] = useState([])
  const latestDate = GetLatestFileByDate()
  const [toggle, setToggle] = useState(false)

  const { fetchedCurrentDate, fetchedAppName } = fetchedData
  let ReactFlowSteps = []

  //Get the latest update when the terminal renders again
  if (latestDate !== undefined && toggle === false) {
    fetchDataFile(getFormattedDate(latestDate), fetchedData.fetchedAppName).then((data) => setCurrentSteps(data))
    setToggle(true)
  }
  // Changes the KPI Tree layout when the user switches dates //
  useEffect(() => {
    if (fetchedData.fetchedCurrentDate !== null) {
      fetchDataFile(getFormattedDate(fetchedCurrentDate), fetchedAppName).then((data) => setCurrentSteps(data))
    }
  }, [fetchedData.fetchedCurrentDate])

  if (currentSteps.length) {
    ReactFlowSteps = currentSteps
  }
  //------//

  return (
    <>
      {/* Replace the background to have bg background instead of white blank screen while it waits to load */}
      {ReactFlowSteps.length ? (
        <ReactFlowProvider>{mode === 'profile' && <CampaignProfile steps={ReactFlowSteps} />}</ReactFlowProvider>
      ) : (
        <div style={{ backgroundImage: `url(${BackgroundGradient})`, width: '100%', height: '100%' }}></div>
      )}
    </>
  )
}
const rootElement = document.getElementById('root')
ReactDOM.render(<StrictMode></StrictMode>, rootElement)

export default function KpiTree() {
  return <App />
}
