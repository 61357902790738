/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { Button } from '@material-ui/core'
import Datepickr from './DatePickr'
import { GetLatestFileByDate } from '../../utils/getLatestFileDate'
import { useFetchedData } from './hooks/FetchedDataContext'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function DateModal() {
  let lastestFileDate = GetLatestFileByDate()
  const [selected, setSelected] = useState(lastestFileDate)
  const { fetchedData, setFetchedData } = useFetchedData()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (selected !== undefined) {
      setFetchedData({ ...fetchedData, fetchedCurrentDate: selected })
    }
  }, [selected])

  return (
    <div>
      <Button onClick={handleOpen} style={{ color: 'white' }}>
        {selected !== undefined
          ? selected.toDateString()
          : lastestFileDate !== undefined
          ? lastestFileDate.toDateString()
          : null}
      </Button>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            <Datepickr
              setSelectedDate={(selected) => {
                setSelected(selected)
              }}
              closeSelectedDate={(open) => {
                setOpen(open)
              }}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  )
}
