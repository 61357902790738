import React, { useState, useContext } from 'react'

const FetchedDataContext = React.createContext()

//Custom Hook
export function useFetchedData() {
  return useContext(FetchedDataContext)
}

export function FetchedDataProvider({ children }) {
  const [fetchedData, setFetchedData] = useState({
    fetchedDates: [],
    fetchedCurrentDate: null,
    fetchedAppName: 'magapoke',
  })

  return <FetchedDataContext.Provider value={{ fetchedData, setFetchedData }}>{children}</FetchedDataContext.Provider>
}
