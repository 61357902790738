import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { useNavigate } from 'react-router-dom'

export default function MainListItems() {
  const navigate = useNavigate()

  return (
    <div>
      <ListItem
        button
        onClick={() => {
          navigate('/')
        }}>
        <ListItemIcon style={{ color: '#FFFFFF' }}>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary='Dashboard' />
      </ListItem>
    </div>
  )
}
