import React, { useState, useMemo, useEffect } from 'react'
import ReactFlow from 'reactflow'
import Node from './Node'
import { StepsList } from './Node'
import Popover from './Popover'
import { generateFlow } from '../utils'
import BackgroundGradient from '../../../assets/img/gradientColorBg.jpg'

const Flow = ({ mode, steps }) => {
  const [stepDetails, setStepDetails] = useState(null)
  const [update, setUpdate] = useState(false)

  useEffect(() => {
    setUpdate(false)
    setTimeout(() => {
      setUpdate(true)
    }, 1)
  }, [steps])

  StepsList(steps)

  const nodeTypes = useMemo(() => {
    return {
      basic: Node,
    }
  }, [])

  /* ノードの横間隔 */
  const width = mode === 'profile' ? 180 : 400 // node spacing between other nodes
  /* ノードの縦間隔 */
  const height = mode === 'profile' ? 38 : 390
  const flow = generateFlow(width, height, steps)

  const defaultNodes = useMemo(() => {
    const nodes = flow.map((node, index) =>
      index === 0
        ? {
            id: `${mode}-${node.id}`,
            type: 'basic',
            data: { ...node },
            position: { x: 90, y: 19 },
            sourcePosition: 'right',
            targetPosition: 'left',
            className: mode === 'profile' ? 'node' : 'fullnode',
          }
        : {
            id: `${mode}-${node.id}`,
            type: 'basic',
            data: { ...node },
            position: { x: node.x, y: node.y },
            sourcePosition: 'right',
            targetPosition: 'left',
            className: mode === 'profile' ? 'node' : 'fullnode',
          }
    )

    return nodes
    // eslint-disable-next-line
  }, [steps, mode])

  const defaultEdges = useMemo(() => {
    const edges = steps
      .map(({ id, previous, sub }) =>
        previous.map(({ stepId: previousId, count, label }) =>
          StepsList(steps, true).includes(previousId) && sub
            ? {
                id: `${mode}-${id}-${previousId}`,
                source: `${mode}-${previousId}`,
                target: `${mode}-${id}`,
                animated: true,
                arrowHeadType: 'none',
                style: {
                  strokeWidth: 2, //react flow width - the lines connecting the nodes
                  stroke: 'rgb(152,152,152)',
                },
                type: 'smoothstep',
                sourceHandle: 'subParent',
              }
            : StepsList(steps, true).includes(previousId) && !sub
            ? {
                id: `${mode}-${id}-${previousId}`,
                source: `${mode}-${previousId}`,
                target: `${mode}-${id}`,
                animated: true,
                arrowHeadType: 'none',
                style: {
                  strokeWidth: 2, //react flow width - the lines connecting the nodes
                  stroke: 'rgb(152,152,152)',
                },
                type: 'smoothstep',
                sourceHandle: 'default',
              }
            : {
                id: `${mode}-${id}-${previousId}`,
                source: `${mode}-${previousId}`,
                target: `${mode}-${id}`,
                animated: true,
                arrowHeadType: 'none',
                style: {
                  strokeWidth: 2, //react flow width - the lines connecting the nodes
                  stroke: 'rgb(152,152,152)',
                },
                type: 'smoothstep',
              }
        )
      )
      .flat()

    return edges
  }, [steps, mode])

  return (
    <div
      style={{
        height: '100%',
        backgroundImage: `url(${BackgroundGradient})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        //Background image for the whole KPI tree
      }}>
      {update && (
        <>
          <ReactFlow
            onNodeClick={(evt, node) => {
              setStepDetails({ evt: evt.currentTarget, node })
            }}
            nodesDraggable={false}
            nodesConnectable={false}
            elementsSelectable={false}
            panOnDrag={mode === 'fullscreen'}
            zoomOnPinch={mode === 'fullscreen'}
            zoomOnScroll={mode === 'fullscreen'}
            zoomOnDoubleClick={mode === 'fullscreen'}
            nodeTypes={nodeTypes}
            defaultNodes={defaultNodes}
            defaultEdges={defaultEdges}
            panOnScroll
            translateExtent={[
              [0, 0],
              [Infinity, Infinity], //scrollLimitSize.y + scrollLimitSize.x
            ]}
          />
          <Popover
            anchor={stepDetails?.evt || null}
            onClose={() => setStepDetails(null)}
            nodeData={stepDetails?.node || null}
            onBottom={true}
          />
        </>
      )}
    </div>
  )
}

export default Flow
