import React from 'react'
import { Typography } from '@material-ui/core'
import BackgroundGradient from '../../assets/img/gradientColorBg.jpg'

export default function MonthlyActiveUser() {
  return (
    <Typography
      variant='h4'
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${BackgroundGradient})`,
        color: 'white',
      }}>
      -- // 工事中 // --
    </Typography>
  )
}
