import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Auth } from 'aws-amplify'

export default function ConfirmationModal({ handleOpenModal, setHandleOpenModal, email, authStatus }) {
  const [confirmationCode, setConfirmationCode] = useState('')

  //Handle verification errors
  const [invalidCode, setInvalidCode] = useState(false)

  const handleClose = () => {
    setHandleOpenModal(false)
  }

  const handleConfirmSignup = async (event) => {
    event.preventDefault()
    setInvalidCode(false)
    try {
      await Auth.confirmSignUp(email, confirmationCode)
      setHandleOpenModal(false)
      authStatus('signIn')
    } catch (error) {
      setInvalidCode(true)
    }
  }

  return (
    <div>
      <Dialog maxWidth={'xs'} open={handleOpenModal} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            アカウントを確認するには、サインアップに使用したアカウントに送信されたコードを入力します。
          </DialogContentText>
          <TextField
            error={invalidCode}
            autoFocus
            id='outlined-basic'
            label='
            検証コード'
            variant='outlined'
            type='number'
            fullWidth
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            キャンセル
          </Button>
          <Button onClick={handleConfirmSignup} color='primary'>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
