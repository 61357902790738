import React from 'react'
import crown from '../../../assets/img/crown.png'
import target from '../../../assets/img/target.png'
import subTree from '../../../assets/img/sub.png'
import { Handle, Position } from 'reactflow'
import { makeStyles } from '@material-ui/core/styles'

let subTreeParentsArray = []

export const StepsList = (steps, returnValue = false) => {
  subTreeParentsArray = []
  subTreeParentsArray.push(...steps.filter((value) => value.sub).map((value) => value.previous[0].stepId))

  if (returnValue) {
    return subTreeParentsArray
  }
}

const Node = ({ data: nodeData }) => {
  // Formats Number Values with Commas
  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const useStyles = makeStyles(() => ({
    nodePercentStatusColor: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: () => {
        if (nodeData.data.percentOK?.value >= 0) {
          return '#ffff00'
        } else {
          return '#F50057'
        }
      },
    },
    wholeNumberSize: {
      fontSize: 12,
    },
    decimalFontSize: {
      fontSize: 10,
    },
    triangleIconSize: {
      fontSize: 12,
      paddingRight: 3,
    },
  }))
  const classes = useStyles()

  //Splitting Decimal and Whole number
  const separatedFloatingNumber = (nodeData.data.percentOK?.value).toString().split('.')

  return (
    <>
      <div className={`node-container ${nodeData.sub ? 'node-sub-container-color' : 'node-default-container-color'}`}>
        <div className={`node-status node-status-${nodeData.sub ? 'subtree' : nodeData.phase.toLowerCase()}`}>
          {/* KGI/KPI */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              padding: 3,
            }}>
            {/* Icon */}
            {nodeData.phase === 'KGI' ? (
              <img src={crown} width='14' height='14' alt='crown' />
            ) : nodeData.sub ? (
              <img src={subTree} width='14' height='14' alt='subtree' />
            ) : (
              <img src={target} width='14' height='14' alt='target' />
            )}
            &nbsp;
            {nodeData.name}
          </div>
        </div>
        {/* ノードの右側のポッチ */}
        {!nodeData.final && !nodeData.sub && (
          <Handle
            type='source'
            position={Position.Right}
            style={{
              border: '1px solid rgb(152,152,152)',
              backgroundColor: 'black',
            }}
            isConnectable={false}
            id='default'
          />
        )}
        {/* ノードの左ぽっち */}
        {/* adds only the subtree child variable top position */}

        {nodeData.previous.length > 0 && !nodeData.sub && (
          <Handle
            type='target'
            position={Position.Left}
            style={{
              border: '1px solid rgb(152,152,152)',
              backgroundColor: 'black',
            }}
            isConnectable={false}
          />
        )}
        {/* adds only the subtree parent variable bottom position */}
        {subTreeParentsArray.includes(nodeData.id) && (
          <Handle
            type='source'
            position={Position.Bottom}
            style={{
              border: '1px solid rgb(152,152,152)',
              backgroundColor: 'black',
            }}
            isConnectable={false}
            id='subParent'
          />
        )}
        {nodeData.sub && (
          <Handle
            type='target'
            position={Position.Top}
            style={{
              border: '1px solid rgb(152,152,152)',
              backgroundColor: 'black',
            }}
            isConnectable={false}
          />
        )}

        <div
          style={{
            height: '100%',
            width: '100%',
            marginTop: 5,
            flex: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          {/* ターゲット数字 */}
          <div
            style={{
              display: 'flex',
            }}>
            <div style={{ fontWeight: 'bold', fontSize: '18px', fontFamily: 'Oswald' }} title='Target'>
              {numberWithCommas(nodeData.data.target)}
            </div>
          </div>
          {/* チェックアイコンと数字 */}
          <div
            style={{
              width: '100%',
            }}>
            <div
              className={classes.nodePercentStatusColor}
              style={{ fontSize: '12px', marginTop: 2 }}
              title={nodeData.data.percentOK?.label}>
              {nodeData.data.percentOK?.value >= 0 ? (
                <span className={classes.triangleIconSize}> &#9650; </span>
              ) : (
                <span className={classes.triangleIconSize}> &#9660; </span>
              )}
              {separatedFloatingNumber[1] === undefined ? (
                <span>{nodeData.data.percentOK?.value}%</span>
              ) : (
                <>
                  <span className={classes.wholeNumberSize}>{separatedFloatingNumber[0]}</span>
                  <span className={classes.decimalFontSize}>.{separatedFloatingNumber[1]}%</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Node
