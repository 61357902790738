import axios from 'axios'

export async function checkLocation() {
  // Determine the user's location based on their IP address
  const location = await axios.get('https://ipapi.co/json/')

  if (location.data.country_name !== 'Japan') {
    // Redirect the user to an error page if they are not in Japan
    alert('アクセスが制限されています (Access Denied)')
    window.location.replace('about:blank')
  } else {
    return false
  }
}
