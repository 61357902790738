import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

export default function ChartData({ historicalData, startDate }) {
  function convertDateToMMDD(dateString) {
    const date = new Date(dateString)
    const month = date.getMonth() + 1
    const day = date.getDate()

    return `${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`
  }
  function getDateCorrespondsToWeek(date, index) {
    let newDate = new Date(date)

    newDate.setDate(newDate.getDate() - 7 * index)
    let mmdd = convertDateToMMDD(newDate)
    return mmdd
  }

  // eslint-disable-next-line
  let data = historicalData.map((value, index) => {
    if (typeof value === 'number') {
      return {
        name: getDateCorrespondsToWeek(startDate, index),
        data: value,
        percentage: false,
      }
    } else if (typeof value === 'string') {
      return {
        name: getDateCorrespondsToWeek(startDate, index),
        data: parseFloat(value),
        percentage: true,
      }
    }
  })

  return (
    <ResponsiveContainer width='100%' height={150}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' angle={-90} textAnchor='end' interval={0} reversed height={50} />
        <YAxis
          unit={data[0].percentage ? '%' : null}
          width={65}
          domain={[(dataMin) => Math.floor(dataMin * 0.9), (dataMax) => Math.ceil(dataMax * 1.01)]}
        />
        <Tooltip />
        <Bar dataKey='data' fill='#9ACD32' unit={data[0].percentage ? '%' : null} />
      </BarChart>
    </ResponsiveContainer>
  )
}
