import React, { useEffect } from 'react'
import { useSessionUpdate } from '../hooks/SessionContext'
import { Amplify, Hub, Auth } from 'aws-amplify'
import SideDesignAuth from '../components/SideDesignAuth'
import awsExports from '../../../aws-exports'

Amplify.configure(awsExports)

export default function Authenticate({ children }) {
  const { sessionLog, setSessionLog } = useSessionUpdate()

  useEffect(() => {
    //When logging in, it fires up the Auth
    Hub.listen('auth', () => {
      Auth.currentAuthenticatedUser().then((data) => setSessionLog(data))
    })

    //Checks to see if the user has already logged in
    Auth.currentAuthenticatedUser().then((data) => setSessionLog(data))
    
    //add eslint disable since useEffect will only need to work once
    // eslint-disable-next-line
  }, [])

  return <>{sessionLog ? children : <SideDesignAuth />}</>
}
