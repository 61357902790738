/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { format } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { GetLatestFileByDate } from '../../utils/getLatestFileDate'
import { useFetchedData } from './hooks/FetchedDataContext'
import { processArray } from './../../utils/getProcessedDates'

export default function DatePickr({ setSelectedDate }) {
  let lastestFileDate = GetLatestFileByDate()
  const { fetchedData } = useFetchedData()
  const [selected, setSelected] = useState(lastestFileDate)

  useEffect(() => {
    setSelectedDate(selected) // props to update to dashboard nav date
  }, [selected])

  let processedFetchedDates = []
  if (fetchedData.fetchedDates.length) {
    processArray(fetchedData.fetchedDates).map((value) => {
      return processedFetchedDates.push(value.toString())
    })
  }

  let startDate = new Date(2022, 0, 1)
  let endDate = new Date(2032, 11, 31) // Please change if the date is more than the end date

  let dates = []

  if (processedFetchedDates.length) {
    while (startDate <= endDate) {
      if (!processedFetchedDates.includes(`${startDate}`)) {
        dates.push(new Date(startDate))
      }
      startDate.setDate(startDate.getDate() + 1)
    }
  }

  let footer = <p>Please pick a day.</p>
  if (selected) {
    footer = <p>You picked {format(selected, 'PP')}.</p>
  }
  return <DayPicker mode='single' disabled={dates} selected={selected} onSelect={setSelected} footer={footer} />
}
