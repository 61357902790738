import React from 'react'
import { Auth } from 'aws-amplify'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ListSubheader from '@material-ui/core/ListSubheader'
import AssignmentIcon from '@material-ui/icons/Assignment'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import { useNavigate } from 'react-router-dom'
import { useSessionUpdate } from './../../features/authentication/hooks/SessionContext'

export default function SecondaryListItem({ open }) {
  const navigate = useNavigate()
  const { setSessionLog } = useSessionUpdate()
  const handleSignOut = async () => {
    navigate('/')
    await Auth.signOut()
    setSessionLog(false)
  }

  return (
    <List>
      <ListSubheader>
        <Fade in={open}>
          <Typography style={{ color: '#FFFFFF', textAlign: 'center' }}>Saved reports</Typography>
        </Fade>
      </ListSubheader>
      <ListItem
        button
        onClick={() => {
          navigate('/weekly')
        }}>
        <ListItemIcon style={{ color: '#FFFFFF' }}>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary='週次レポート' />
      </ListItem>
      <ListItem
        button
        onClick={() => {
          navigate('/monthly')
        }}>
        <ListItemIcon>
          <AssignmentIcon style={{ color: '#FFFFFF' }} />
        </ListItemIcon>
        <ListItemText primary='月次レポート' />
      </ListItem>

      <ListItem button onClick={handleSignOut}>
        <ListItemIcon>
          <ExitToAppIcon style={{ color: '#FFFFFF' }} />
        </ListItemIcon>
        <ListItemText primary='ログアウト' />
      </ListItem>
    </List>
  )
}
