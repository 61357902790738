import React, { useState, useContext } from 'react'

const SessionContext = React.createContext()

//Custom Hook
export function useSessionUpdate() {
  return useContext(SessionContext)
}

export function SessionProvider({ children }) {
  const [sessionLog, setSessionLog] = useState()

  return <SessionContext.Provider value={{ sessionLog, setSessionLog }}>{children}</SessionContext.Provider>
}
