import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { Auth } from 'aws-amplify'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { Copyright } from './Copyright'
import ConfirmationModal from './ConfirmationModal'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignUp({ authStatus }) {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [handleOpenModal, setHandleOpenModal] = useState(false)

  //Handling error for the credentials
  const [invalidEmail, setInvalidEmail] = useState({ boolean: false, message: '' })
  const [invalidPassword, setInvalidPassword] = useState({ boolean: false, message: '' })

  const handleSubmitSignup = async (event) => {
    event.preventDefault()
    setInvalidEmail({ boolean: false, message: '' })
    setInvalidPassword({ boolean: false, message: '' })

    try {
      await Auth.signUp({
        username: email,
        password: password,
      })
      setHandleOpenModal(true)
    } catch (error) {
      if (error.name === 'InvalidParameterException') {
        setInvalidEmail({ boolean: true, message: 'メールアドレス/パスワードが無効です' })
        setInvalidPassword({
          boolean: true,
          message: '大文字、小文字、数字、特殊文字を含めて 8 文字以上である必要があります',
        })
      }
      if (error.name === 'InvalidPasswordException') {
        setInvalidPassword({
          boolean: true,
          message: '大文字、小文字、数字、特殊文字を含めて 8 文字以上である必要があります',
        })
      }
      if (error.name === 'UsernameExistsException') {
        setInvalidEmail({ boolean: true, message: 'メールは既に存在します' })
      }
      if (error.name === 'UserLambdaValidationException') {
        setInvalidEmail({ boolean: true, message: 'あなたのメール アドレス ドメインは、このサービスでは無効です' })
      }
      if (error.name === 'AuthError') {
        if (passwordConfirmation === '' || password === '') {
          setInvalidPassword({
            boolean: true,
            message: '大文字、小文字、数字、特殊文字を含めて 8 文字以上である必要があります',
          })
        }
        if (email === '') {
          setInvalidEmail({ boolean: true, message: '電子メールを空白にすることはできません' })
        }
      }
    }
  }

  return (
    <>
      <Typography component='h1' variant='h5'>
        サインアップ
      </Typography>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={invalidEmail.boolean}
              helperText={invalidEmail.message}
              variant='outlined'
              required
              fullWidth
              id='email'
              label='電子メールアドレス'
              name='email'
              autoComplete='email'
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={invalidPassword.boolean}
              helperText={invalidPassword.message}
              variant='outlined'
              required
              fullWidth
              name='password'
              label='パスワード'
              type='password'
              id='password'
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={invalidPassword.boolean}
              helperText={invalidPassword.message}
              variant='outlined'
              required
              fullWidth
              name='password-confirmtation'
              label='パスワードを認証する'
              type='password'
              id='password-confirmtation'
              onChange={(e) => setPasswordConfirmation(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={(e) => {
            handleSubmitSignup(e)
          }}>
          サインアップ
        </Button>
        <Grid container justifyContent='flex-end'>
          <Grid item>
            <Link style={{ cursor: 'pointer' }} variant='body2' onClick={() => authStatus('signIn')}>
              すでにアカウントをお持ちですか？サインイン
            </Link>
          </Grid>
        </Grid>
      </form>

      <Box mt={5}>
        <Copyright />
      </Box>
      <ConfirmationModal
        email={email}
        authStatus={(status) => {
          authStatus(status)
        }}
        handleOpenModal={handleOpenModal}
        setHandleOpenModal={(handleOpenModal) => {
          setHandleOpenModal(handleOpenModal)
        }}
      />
    </>
  )
}
