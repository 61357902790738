import { Storage } from 'aws-amplify'

export async function fetchDataFile(date, appName = 'magapoke') {
  const dataStorage = await Storage.get(`applogs/${appName}/${date}_${appName}_data.json`)
  console.log(`applogs/${appName}/${date}_${appName}_data.json`)
  const res = await fetch(dataStorage)
  const resJson = await res.json()

  return resJson
}
