import dagre from 'dagre'

const generateFlow = (width, height, data) => {
  // In a column it gets the first different step id which means it will take the less y value in a node for each the same step id
  // this ensures to line up with the correct order for the previous column

  function getLowestYValues(columnValue) {
    const lastRowNewValues = flowArray.filter((value) => value.x === columnValue && !value.sub)

    const stepIdMap = lastRowNewValues.reduce((acc, value) => {
      const stepId = value.previous[0].stepId
      const y = value.y

      if (!acc[stepId] || y < acc[stepId].y) {
        acc[stepId] = { stepId, y }
      }

      return acc
    }, {})

    const result = Object.values(stepIdMap)

    return result
  }

  //checks an array if the stepid is available or not (it is like includes)
  function findObjectByStepId(array, stepId) {
    return array.find((item) => item.stepId === stepId)
  }

  //this gets the previous functions getLowestYValues & findObjectByStepId to use the data to line up the nodes perfectly
  function getFormattedNodeStructure(lastColumnValue, currentColumn) {
    let currentValue = 19

    currentColumn.forEach((value) => {
      let allFirstNodeID = lastColumnValue.map((value) => value.stepId)

      if (allFirstNodeID.includes(value.id)) {
        currentValue = findObjectByStepId(lastColumnValue, value.id).y
        value.y = currentValue
      } else {
        currentValue += 88
        value.y = currentValue
      }
    })
  }

  function groupObjectsByStepId(objects) {
    let groupedObjects = []
    let stepIdMap = {}

    objects.forEach((obj) => {
      let stepId = obj.previous[0].stepId

      if (stepId in stepIdMap) {
        stepIdMap[stepId].push(obj)
      } else {
        stepIdMap[stepId] = [obj]
      }
    })

    groupedObjects = Object.values(stepIdMap)
    return groupedObjects
  }

  let flow = new dagre.graphlib.Graph()
  flow.setGraph({ rankdir: 'LR' })
  flow.setDefaultEdgeLabel(() => ({}))
  // Set nodes
  data.forEach((node) => {
    flow.setNode(node.id, {
      width,
      height,
      ...node,
    })
  })

  // Set edges
  data.forEach(({ id, previous }) => {
    previous.forEach(({ stepId: previousId }) => {
      flow.setEdge(previousId, id)
    })
  })

  dagre.layout(flow)

  let flowArray = flow.nodes().map((i) => flow.node(i))

  //get every node and rearrange them starting from the top
  let columnValueX = [...new Set(flowArray.map((value) => value.x))].splice(1)

  const copyFourthHierarchyColumnValueX = columnValueX[2] // get the fourth column values
  const copyLastHierarchyColumnValueX = columnValueX[3] // get the last column values
  /* ---- Get all the step id to be used to know the calculated values of spaces between nodes ---- */
  const lastHierarchyStepData = flowArray
    .filter((value) => value.x === copyLastHierarchyColumnValueX)
    .map((obj) => obj.previous.map((p) => p.stepId))
    .reduce((acc, curr) => {
      if (curr in acc) {
        acc[curr]++
      } else {
        acc[curr] = 1
      }
      return acc
    }, {})

  const fourthHierarchyStepData = flowArray
    .filter((value) => value.x === copyFourthHierarchyColumnValueX)
    .map((obj) => obj.previous.map((p) => p.stepId))
    .reduce((acc, curr) => {
      if (curr in acc) {
        acc[curr]++
      } else {
        acc[curr] = 1
      }
      return acc
    }, {})

  /* -------- */

  // Loops through each columns and adds each nodes their respective positions
  columnValueX.forEach((valueInside, valueIndex) => {
    let columnValues = flowArray.filter((allValueX) => allValueX.x === valueInside)
    let previousValue = 19

    if (valueIndex !== 2 && valueIndex !== 1) {
      columnValues.forEach((columnValue, index) => {
        if (index === 0) {
          columnValue.y = 19
        } else {
          columnValue.y = previousValue + 88
          previousValue += 88
        }
      })
    } else if (valueIndex === 1) {
      columnValues.forEach((columnValue, index) => {
        let addValue = fourthHierarchyStepData[columnValue.id] * 88
        if (index === 0) {
          columnValue.y = 19
        } else if (addValue) {
          columnValue.y = previousValue + addValue
          previousValue += addValue
        } else {
          columnValue.y = previousValue + 88
          previousValue += 88
        }
      })
    } else if (valueIndex === 2) {
      columnValues.forEach((columnValue, index) => {
        if (index === 0) {
          columnValue.y = 19
        }
      })
    }
  })

  // get all the last sub
  // const allSubValues = flowArray.filter((value) => value.sub)

  // const lowestSubs = Object.values(
  //   allSubValues.reduce((groupedSubs, sub) => {
  //     const stepId = sub.previous[0].stepId
  //     if (!(stepId in groupedSubs) || stepId < groupedSubs[stepId].previous[0].stepId) {
  //       groupedSubs[stepId] = sub
  //     }
  //     return groupedSubs
  //   }, {})
  // )

  // const lowestSubIds = lowestSubs.map((sub) => sub.id)

  let lastColumnValue = flowArray.filter((value) => value.x === copyLastHierarchyColumnValueX)

  let previousValueY = 19
  groupObjectsByStepId(lastColumnValue).forEach((value) => {
    value.forEach((value) => {
      value.y = previousValueY
      previousValueY += 88
    })
  })

  lastColumnValue.forEach((value) => {})

  //put the subtree children below subtree parent
  flowArray.forEach((value) => {
    if (value.sub) {
      //find the parent of the subtree children
      let parentObject = flowArray.find((valueInside) => valueInside.id === value.previous[0].stepId)
      //find the position of the parent object
      let filteredFlowArray = flowArray.filter(
        (valueInside) => valueInside.x === parentObject.x && valueInside.y >= parentObject.y
      )
      //get the position of the previous location of the subtree children to replace the empty node area
      let filteredPreviousAreaFlowArray = flowArray.filter(
        (valueInside) => valueInside.x === value.x && valueInside.y > value.y
      )

      // removes the empty node area
      filteredPreviousAreaFlowArray.forEach((valueInside) => {
        const minusValue = lastHierarchyStepData[valueInside.id] * 88

        if (valueInside.x === copyFourthHierarchyColumnValueX) {
          valueInside.y -= minusValue
        } else {
          valueInside.y -= 88
        }
      })

      value.y = filteredFlowArray[0].y + 88
      value.x = filteredFlowArray[0].x

      filteredFlowArray.forEach((valueInside, index) => (index === 0 ? valueInside.y : (valueInside.y += 88)))
    }
  })

  const lastColumnFirstNodeValue = getLowestYValues(copyLastHierarchyColumnValueX)
  let fourthColumnNewValue = flowArray
    .filter((value) => value.x === copyFourthHierarchyColumnValueX)
    .sort((a, b) => a.y - b.y)

  getFormattedNodeStructure(lastColumnFirstNodeValue, fourthColumnNewValue)

  const fourthColumnFirstNodeValue = getLowestYValues(copyFourthHierarchyColumnValueX)
  let thirdColumnNewValue = flowArray.filter((value) => value.x === columnValueX[1]).sort((a, b) => a.y - b.y)
  getFormattedNodeStructure(fourthColumnFirstNodeValue, thirdColumnNewValue)

  return flow.nodes().map((i) => flow.node(i))
}

export { generateFlow }
